<template>
    <div class="info">
      <!-- <top-bar :title="'智慧党建'" :left="true"></top-bar> -->
      <div class="infoBox">
        <div class="title">
          <p>{{detailData.title}}</p>
          <span>{{detailData.createTime}}</span>
        </div>
        <van-divider :hairline="false" />
        <div class="content" v-html="detailData.content">
        </div>
      </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      detailData: {}
    }
  },
  computed:{...mapState(['noticeId'])},
  components :{
    topBar
  },
  methods: {
    getDetail () {
      console.log(this.noticeId);
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/commonApp/notice/info/')+ this.noticeId,
        method: 'post',
      }).then(({data})=> {
          if (data.code == 0) {
            this.detailData = data.commonAppNotice
          this.$toast.clear()
          } else {
            console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
        })
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDetail()
  }
}
</script>
<style>
  a {
  color: rgb(4, 50, 255);
}
</style>
<style lang="scss" scoped>
  .van-divider{
    border-color: #000;
  }
  .info {
    // margin-top: 160px;
    padding: 0 20px;
    background-color: #f5f5f5;
    .infoBox {
      background-color: #fff;
      padding: 30px;
      .title {
        p {
          font-size: 36px;
          margin: 10px 0 20px;
        }
        span {
          color: #999;
        }
      }
      .content {
        font-size: 30px;
      }
    }
  }
</style>
